/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// custom typefaces
import "@fontsource/open-sans";
import "./src/styles/typography.css";

require("prismjs/themes/prism.css");
require("katex/dist/katex.min.css");